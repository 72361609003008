<template>
    <v-container fluid style="padding-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item 
                        :to="item.href" 
                        class="text-subtitle-2 crumb-item" 
                        :disabled="item.disabled" 
                        exact>
                        {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <!-- <v-progress-linear
                        :active="loading" :indeterminate="loading" absolute bottom color="deep-purple accent-4">
                    </v-progress-linear> -->
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  solo clearable v-model="office" :items="offices" item-value="office_id" item-text="office" label="MILL ID" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete  solo clearable v-model="vendor_name" :items="departments" item-value="vendor_name" :item-text="item => item.vendor_id +' - '+ item.vendor_name" label="VENDOR" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="5" md="2">
                                 <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete  solo clearable v-model="approve" :items="approves" item-value="value" item-text="descr" label="APPROVED" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px" >
                <v-card v-show="display == 'none' ? false: true">
                <!-- <v-card> -->
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer> 
                                    <div>
                                        <v-btn
                                            v-if="user_approve == 'Y'"
                                            color="primary"
                                            elevation="2"
                                            small
                                            @click="batchApproveOne()" v-on="on">
                                        Po Retur Approval</v-btn>
                                    </div>
                                    <div class="ml-4">
                                        <v-btn
                                            v-if="user_approve2 == 'Y'"
                                            color="primary"
                                            elevation="2"
                                            small
                                            @click="batchApproveTwo()" v-on="on">
                                        Nota Retur Approval</v-btn>
                                    </div>
                                    
                                </v-card-title>
                                <v-data-table
                                    v-model="selected"
                                    show-select
                                    item-key="tr_id"
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.qty`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.qty) }}
                                    </template>
                                    <template v-slot:[`item.wgt`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.wgt) }}
                                    </template>
                                    <template v-slot:[`item.total_amt`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.total_amt) }}
                                    </template>
                                    <template v-slot:[`item.total_qty`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.total_qty) }}
                                    </template>
                                    <template v-slot:[`item.total_wgt`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.total_wgt) }}
                                    </template>
                                    <template v-slot:[`item.total_amt_item`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.total_amt_item) }}
                                    </template>
                                    <template v-slot:[`item.approve`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="user_approve == 'Y'">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.aprv_flag === 'N'"
                                                    @click="setApprove(item, 1)" v-on="on"
                                                    >
                                                    Confirmation <v-icon center>mdi-check-circle</v-icon>
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    v-else>
                                                    {{ (new Date(new Date(item.dt_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.aprv_flag === 'N'"
                                                    >
                                                         Not Approved
                                                    </v-chip>
                                                    
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    v-else-if="item.aprv_flag === 'Y'"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>  
                                    <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="user_approve2 == 'Y'">
                                                    <v-chip
                                                        class="ma-2"
                                                        color="primary"
                                                        v-if="item.aprv_by2.trim() === '' && item.aprv_by.trim() != ''"
                                                        @click="setApprove(item, 2)" v-on="on"
                                                    >
                                                        Confirmation <v-icon center>mdi-check-circle</v-icon>
                                                    </v-chip>
                                                    <v-chip
                                                        class="ma-2"
                                                        color="success"
                                                        v-else-if="item.aprv_by2.trim() === '' && item.aprv_by.trim() === ''">
                                                        Not yet approved
                                                    </v-chip>
                                                    <v-chip
                                                        class="ma-2"
                                                        color="success"
                                                        v-else-if="item.aprv_by2.trim() != '' && item.aprv_by.trim() != ''"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv2) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                        class="ma-2"
                                                        color="red"
                                                        text-color="white"
                                                        v-if="item.aprv_by2.trim() === '' && item.aprv_by.trim() != ''"
                                                    >
                                                         Not Approved
                                                    </v-chip>
                                                    <v-chip
                                                        class="ma-2"
                                                        color="success"
                                                        v-else-if="item.aprv_by2.trim() != '' && item.aprv_by.trim() != ''"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv2) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card> 
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail PO Retur Item</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>PO Item
                                            <v-spacer></v-spacer>
                                            <!-- <v-text-field
                                                v-model="search_detail"
                                            ></v-text-field> -->
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :options.sync="option_po_items"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_po_items"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1">
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt) }}
                                            </template>
                                            <template v-slot:[`item.unit_price`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.unit_price) }}
                                            </template>
                                            <template v-slot:[`item.amt_item`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.amt_item) }}
                                            </template>
                                            <template v-slot:[`item.description`]="{ item }">
                                                {{ item.descr }} , {{ item.descr2 }} 
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this PO Retur?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closeDialogAgree"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                                Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
import {poReturApproval} from "../../../backend-api/sr/finance_approval/po_retur_approval"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Purchase Order Retur Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [{
                office_id : 'Sunrise Steel',
                office : 'Sunrise Steel'
            }],
            office: 'Sunrise Steel',
            departments: [],
            department: '',
            status: '',
            vendor_name:'',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                { text: 'TR Date', value: 'tanggal' },
                { text: 'TR ID', align: 'start', sortable: true, value: 'tr_id' },
                { text: 'PO Id', value: 'po_id' },
                { text: 'No SJ', value: 'sj_no' },
                { text: 'Qty', value: 'total_qty' },
                { text: 'Wgt', value: 'total_wgt' },
                { text: 'Amount', value: 'total_amt_item' },
                { text: 'PO Retur Approval',  value: 'approve', align: 'center', sortable: false },
                { text: 'Nota Retur Approval',  value: 'approve1', align: 'center', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Retur Approved',
                    value: 'approved'
                },
                {
                    descr: 'Nota Approved',
                    value: 'approved2'
                },
                {
                    descr: 'Retur Not Approved',
                    value: 'not_approve'
                },
                {
                    descr: 'Nota Not Approved',
                    value: 'not_approve2'
                }
            ],
            approve: '',
            user_approve:'',
            user_approve2:'',
            dialogAgree:false,
            level:0,
            selected:[]
        }
    },
    mounted(){
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'PORETURAPRV') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve = res.data.data[i]['var_value']
                        }
                    }
                    if (res.data.data[i]['var_id'] === 'NOTARETURAPRV') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                        }
                    }
                }
                console.log('this.user_approve = ');
                console.log(this.user_approve);
            }).catch(error => {
                // Handle any errors from the dispatch call
                console.error('Error fetching variable user:', error);
            });
            
        },
        search(){
            this.getPullData(1, 10, true)
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/po/getRejectPO?txtApproved=${this.approve ? this.approve : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, {
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(this.state.text)
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        showItem(item){
            this.header_po_items = [
                { text: 'PO Id', align: 'start', sortable: true, value: 'po_id' },
                { text: 'PR Id', align: 'start', sortable: true, value: 'pr_id' },
                { text: 'Description', value: 'description' },
                { text: 'Coil ID', value: 'coil_id' },
                { text: 'Weight', value: 'wgt' },
                // { text: 'Qty', value: 'qty' },
                // { text: 'Unit Meas', value: 'unit_meas' },
                // { text: 'Unit Price', value: 'unit_price' },
                // { text: 'Amount', value: 'amt_item' },
            ]
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
            
        },
        async getPullDataDetail(page = 1, itemsPerPage = 10, item){
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/po/getDetailRejectPO?search=${this.search_detail}&tr_id=${item.tr_id}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading3 = false
                this.po_items = res.data.data
                this.totalItem_po_items = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        close() {
            this.dialog = false
        },
        closeDialogAgree(){
            this.dialogAgree = false
        },
        setApprove(item, lvl){
            this.dialogAgree = true
            this.po_item = item
            this.level = lvl
        },
        async submitApprove(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/po/approveReturPO`, {
                'tr_id': this.po_item.tr_id,
                'level': this.level
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async batchApproveOne(){
            this.$store.dispatch('setOverlay', true)
            const filteredData = this.selected.filter(item => item.aprv_by.trim() === '' && item.aprv_flag === 'N');
            const a = {
                "batch" : filteredData,
            }
            const respData = await poReturApproval.batchOne('', a)
            if(respData.status == 200){
                this.getPullData()
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            }
        },
        async batchApproveTwo(){
            this.$store.dispatch('setOverlay', true)
            const filteredData = this.selected.filter(item => item.aprv_by.trim() && item.aprv_flag === 'Y');
            const a = {
                "batch" : filteredData,
            }
            const respData = await poReturApproval.batchTwo('', a)
            if(respData.status == 200){
                this.getPullData()
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            }
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>