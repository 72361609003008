import {backendApi} from "../../backend-api-sr"

export const poReturApproval = (() =>{
    const batchOne = ( async (subPath, reqBody) => {
        var url = '/api/sr/po/batch-one'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const batchTwo = ( async (subPath, reqBody) => {
        var url = '/api/sr/po/batch-two'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {batchOne, batchTwo};
})()